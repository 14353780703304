'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function injectIframe(src) {
  var iframe = document.createElement('iframe');
  iframe.src = src;
  document.body.appendChild(iframe);
  iframe.style.width = '1px';
  iframe.style.height = '1px';
  iframe.style.position = 'fixed';
  iframe.style.left = '-1px';

  return iframe;
}

function ejectIframe(iframe) {
  document.body.removeChild(iframe);
}

function browserDeeplink(appLink, options) {
  if ( options === void 0 ) options = {
  waitTimeout: 200,
};

  var waitTimeout = options.waitTimeout;

  return new Promise((function (resolve, reject) {
    var iframe = injectIframe(appLink);
    var timeout = setTimeout(function () {
      window.removeEventListener('blur', windowBlurListener);
      ejectIframe(iframe);
      reject(Error(("Can't open " + appLink)));
    }, waitTimeout);

    function windowBlurListener() {
      window.removeEventListener('blur', windowBlurListener);
      clearTimeout(timeout);
      ejectIframe(iframe);
      resolve();
    }

    window.addEventListener('blur', windowBlurListener);
  }));
}

exports.browserDeeplink = browserDeeplink;
